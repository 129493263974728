// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

.fw-light {
    font-weight: 300 !important;
}

.fw-regular {
    font-weight: 400 !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

:root {
    scrollbar-color: rgba(0,0,0,.2)!important;
    scrollbar-width: thin!important
}

::-webkit-scrollbar {
    width: 9px
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0,0,0,.2)
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,.4)
}

::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 5px;
    background: rgba(0,0,0,.2)
}

.fw-medium.stat_name {
    font-size: 23px;
}

.modal.warningModal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.visit_station_btn {
    display: block;
    width: 100%;
    margin-top: 2em;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 14px;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    transition: all .3s ease
}

.visit_station_btn:hover {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    color: #3c4b64;
    transition: all .3s ease
}

@media (min-width: 992px) {
    .sidebar {
        --cui-sidebar-width: 320px;
    }
    .sidebar:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 320px;
    }
}

@media (min-width: 1700px) {
    .sidebar {
        --cui-sidebar-width: 520px;
    }
    .sidebar:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 520px;
    }
}

.whiteFontColor {
    color: #ffffff !important;
}

.header.header-sticky {
    background: #3B405E;
    border-color: #464750 !important;
    max-height: 63px;
    min-height: 63px;
    padding: 0;
}

.header-divider {
    border-color: #464750 !important;
}

.header-nav .show > .nav-link, .header-nav .nav-link.active,
.header-nav .nav-link:focus, .header-nav .nav-link:hover {
    color: #ffffff;
}

.header-toggler .icon rect.ci-primary {
    fill: #ffffff;
}

.header-nav .nav-link {
    color: hsla(0,0%,100%,.75);
}

body, .body {
    background-color: #16183E;
}

.class_stations td strong {
    font-weight: 400;
}

.body {
    padding-top: 2em;
}

.sidebar {
    background-color: #16183E;
}

.card {
    background-color: #16183E;
    color: rgba(255, 255, 255, 0.87);
    border: none;
}

.table, .text-medium-emphasis {
    color: rgba(255, 255, 255, 0.87) !important;
}

.table tr:hover td {
    color: rgba(255, 255, 255, 0.57) !important;
}

.table {
    border: none !important;
}

.footer, .footer a {
    --cui-footer-bg: #16183E;
    border-color: #16183E;
    color: hsla(0,0%,100%,.87) !important;
}

.header .breadcrumb a {
    color: hsla(0,0%,100%,.87) !important;
}

.example .tab-content {
    background-color: #24252f !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #24252f;
}

.table > :not(caption) > * > * {
    color: hsla(0,0%,100%,.87) !important;
    background-color: #16183E;
}

.table tr {
    border-color: #16183E !important;
}

.sidebar-brand {
    background-color: #3B405E;
    max-height: 63px;
    min-height: 63px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header .container-fluid:first-child {
    padding: 0;
}

.header .header-nav .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 17px;
    padding-top: 20px;
}

.sidebar-brand-full {
    width: max-content;
    height: 21px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 43px;
    padding-right: 43px;
}

.bottom_icon {
    max-width: 260px;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 43px;
}

.table th {
    color: #5F607A !important;
    font-size: 16px;
    line-height: normal;
    padding-left: 0;
    padding-right: 5px;
    font-weight: 400;
}

.table th:first-child {
    color: #5F607A !important;
    font-size: 16px;
    line-height: normal;
    padding-left: 10px;
    padding-right: 5px;
}

.class_stations .sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 0 !important;
    transition: none !important;
}

.class_stations .sidebar.sidebar-fixed {
    display: none !important;
}

.class_stations .header-toggler {
    display: none !important;
}

.class_stations {
    padding-bottom: 50px;
}

.class_stations .table td:first-child {
    width: 295px !important;
    padding-left: 10px;
}

.class_stations .table td:nth-child(2) {
    width: 110px;
}

.class_stations .table td:nth-child(3) {
    width: 110px;
}

.class_stations .table td:nth-child(4) {
    width: 125px;
}

.class_stations .table td:nth-child(5) {
    width: 125px;
}

.class_stations .table td:nth-child(6) {
    width: 202px;
}

.class_stations .table td:nth-child(7) {
    width: 195px;
}

.class_stations .table td:nth-child(8) {
    width: 175px;
}

.class_stations .table td:nth-child(9) {
    width: 165px;
}

.class_stations .table td:nth-child(10) {
    width: 95px;
}

.class_stations .table td:nth-child(11) {
    width: 165px;
}

.class_stations .table td:nth-child(12) {
    width: 85px;
    padding-right: 10px;
}

.table td {
    color: #ffffff !important;
    font-size: 23px;
    font-weight: 500;
    padding: 0;
}

.class_stations .container-lg {
    max-width: 100% !important;
}

.table .secondary-th {
    text-transform: initial;
    padding-left: 10px;
    padding-right: 5px;
}

.table tbody tr {
    border-bottom: 1px solid #32F5E6 !important;
}

table.associatedStationsTable td {
    color: #5F607A !important;
}

.tab_nav_item a.nav-link.active {
    border-bottom: 3px solid #32F5E6;
}

.tab_nav_item a.nav-link {
    border-bottom: 3px solid #3B405E;
    min-width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.class_maps .body {
    padding: 0 !important;
}

.class_maps .container-lg {
    max-width: 100%;
    padding: 0 !important;
}

.map_memorandum {
    position: absolute;
    background: #F0F0F0;
    padding: 16px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 11px;
    right: 27px;
    bottom: 20px;
    z-index: 9999;
}

.map_memorandum div {
    color: #3266F5;
    font-size: 9px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 300;
}

.map_memorandum div:last-child {
    margin-bottom: 0;
}

.map_memorandum div:before {
    content: url('../assets/images/map_memorandum-icon.svg');
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.position-relative {
    position: relative;
}

.map_memorandum_dots {
    position: absolute;
    bottom: 43px;
    left: 125px;
    display: flex;
    color: #000000;
    font-size: 9px;
    z-index: 9999;
}

.map_memorandum_dots .dot_memorandum:first-child:before {
    content: url(../assets/images/active_pin_memorandum.svg);
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.map_memorandum_dots .dot_memorandum:nth-child(2):before {
    content: url(../assets/images/uncalibrated_pin_memorandum.svg);
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.map_memorandum_dots .dot_memorandum:nth-child(3):before {
    content: url(../assets/images/no_data_pin_memorandum.svg);
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.map_memorandum_dots .dot_memorandum:last-child:before {
    content: url(../assets/images/inactive_pin_memorandum.svg);
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.dot_memorandum {
    display: flex;
    align-items: center;
    font-weight: 300;
}

.ol-map {
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    height: calc(100vh - 63px);
}

.leaflet-control-attribution.leaflet-control {
    display: none !important;
}

.leaflet-control-container {
    height: 100%;
    position: relative;
}

.leaflet-control-zoom.leaflet-control {
    position: absolute;
    background: transparent !important;
    bottom: 30px;
    left: 33px;
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    display: flex;
    margin-bottom: 6.5px !important;
}

.leaflet-control-zoom.leaflet-control a {
    background: transparent;
    border: none !important;
    color: #fff;
    font-size: 30px;
    box-shadow: none;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
    background-color: transparent !important;
}

.leaflet-control-zoom.leaflet-control a span {
    font-family: initial !important;
    filter: invert(1);
}

.leaflet-popup-content {
    text-align: center;
}

.leaflet-top {
    height: 100%;
}

body[class*='class_station\/'] #stations_nav,
.class_stations #stations_nav {
    border-bottom: 3px solid #32F5E6 !important;
}

.sidebar-nav {
    display: none;
}

#map_info_wrapper, #station_info_wrapper, #map_station_wrapper {
    display: none;
    position: relative;
    flex: 1 1;
    flex-direction: column;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    padding-top: 25px;
    padding-bottom: 45px;
    padding-left: 43px;
    padding-right: 43px;
}

.class_info #map_info_wrapper {
    display: flex;
}

.class_stations #map_info_wrapper,
body[class*='class_station\/'] #station_info_wrapper {
    display: flex;
}

.class_maps #map_station_wrapper {
    display: flex;
}

.map_info_desc {
    color: #E4E4E4;
    font-size: 12px;
    max-width: 85%;
    font-weight: 300;
}

.map_info_title {
    color: #6A6B83;
    font-size: 18px;
}

.station_tabs {
    display: flex;
    border-bottom: none;
    margin-top: 30px;
    margin-bottom: 50px;
}

.station_tabs li.nav-item {
    max-width: 33%;
    width: 100%;
}

.station_tabs a.nav-link {
    background-color: transparent !important;
    border: none !important;
    color: #838498;
    font-size: 15px;
    padding: 0;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 3px solid #23808D !important;
}

.station_tabs a.nav-link.active {
    color: #ffffff;
    border-bottom: 3px solid #32F5E6 !important;
}

.width-85 {
    width: 85%;
}

.width-100 {
    width: 100%;
}

.indications .value {
    display: flex;
    color: #23808D;
    font-weight: 300;
    font-size: 21px;
}

.indications .label {
    color: #9091A3;
    font-size: 9px;
}

.indications {
    display: flex;
}

.indication {
    margin-right: 15px;
}

.indications .indication:first-child .value,
.indications .indication:nth-child(2) .value,
.indications .indication:nth-child(3) .value {
    color: #ffffff;
}

.indications .indication:nth-child(3) {
    margin-right: 40px;
}

.daily_overview_chart {
    background: transparent;
    border-radius: 43px;
    padding: 3em;
}

.station_tabs li a {
    cursor: pointer;
}

.station_info_gray {
    color: #6A6B83;
    font-size: 17px;
}

.main_station_name {
    color: #FFFFFF;
    font-size: 34px;
    margin-left: 19px;
}

.station_img {
    border-radius: 21px;
    height: auto;
    margin-bottom: 30px;
    margin-top: 24px;
    object-fit: cover;
    width: 100%;
    max-height: 250px;
}

.station_desc {
    color: #E4E4E4;
    font-size: 14px;
    margin-bottom: 28px;
    max-width: 87%;
    word-break: break-word;
    font-weight: 300;
}

.react-datepicker__input-container input {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.real_view, .gray-station-info {
    color: #A1A2B1;
    font-size: 12px;
    margin-bottom: 13px;
}

.real_view_img {
    border-radius: 11px;
    height: auto;
    object-fit: cover;
    width: 100%;
}

.flex-title {
    display: flex;
    align-items: center;
}

#map_station_wrapper .station_desc {
    margin-top: 20px;
}

#map_station_wrapper .station_img {
    margin-top: 0;
}

.react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous {
    top: 4px !important;
}

.react-datepicker__input-container input {
    border-radius: 50px;
    padding: 0.5em;
    padding-left: 4em;
    padding-right: 1em;
    cursor: pointer;
}

.station-white-info {
    color: #ffffff;
    font-size: 18px;
}

.gray-station-info {
    margin-bottom: 0;
}

.flex-info {
    display: flex;
    justify-content: space-between;
}

.font-weight-bold {
    font-weight: bold;
}

.institution_img {
    width: max-content;
    height: 109px;
    object-fit: contain;
    background-color: #F0F0F0;
    border-radius: 12px;
    margin-top: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.station_metrics {
    margin-top: 20px;
}

.station_dates_info .label {
    color: #A1A2B1;
    font-size: 12px;
}

.station_dates_info .white_label {
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 12px;
}

.station_dates_info {
    justify-content: space-between;
}

.station-metrics-info {
    max-width: 84%;
}

.leaflet-popup-content-wrapper {
    font-family: 'Poppins';
}

.station_metrics .label {
    font-size: 10px;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 700;
}

.station_metrics .gray_label {
    color: #A1A2B1;
    font-size: 12px;
}

.station_metrics .value {
    color: #FFFFFF;
    font-size: 12px;
    margin-left: 14px;
}

#right_sidebar {
    position: fixed;
    top: 167px;
    padding-right: 1em;
    overflow: scroll;
    height: 85vh;
}

#right_sidebar .gray-station-info {
    font-size: 12px;
    margin-top: 5px;
}

#right_sidebar .station-white-info {
    font-size: 15px;
}

#right_sidebar .station_dates_info .label {
    font-size: 12px;
    margin-bottom: 5px;
}

#right_sidebar .station_dates_info .white_label {
    font-size: 14px;
    margin-bottom: 5px;
}

#right_sidebar .station_metrics .label {
    font-size: 12px
}

#right_sidebar .station_metrics .gray_label,
#right_sidebar .station_metrics .value {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 8px;
}

#right_sidebar .station_dates_info {
    margin-top: 20px;
}

#right_sidebar .station_metrics {
    margin-top: 10px;
}

.trajectories_weather_section img {
    background-color: #ffffff;
    border-radius: 15px;
    object-fit: cover;
    width: 144.91px;
    height: 114.9px;
}

.trajectories_weather_section .gray-station-info {
    margin-left: 10px;
    margin-bottom: 10px;
}

.trajectories_weather_section {
    margin-top: 25px;
}

#station_selection, #station_selection:focus, #station_selection:active,
#station_selection_maps, #station_selection_maps:focus, #station_selection_maps:active {
    background: transparent;
    border: none;
    padding: 0;
    line-height: normal;
    outline: 0;
    box-shadow: none;
    color: #FFFFFF;
    font-size: 34px;
    margin-left: 19px;
    cursor: pointer;
}

#station_selection option,
#station_selection_maps option {
    color: #000000 !important;
    cursor: pointer;
    font-size: 20px;
}

#station_selection option:disabled,
#station_selection_maps option:disabled {
    opacity: 0.5;
    color: #A1A2B1 !important;
    cursor: not-allowed !important;
}

.class_stations .map_info_title {
    margin-bottom: 20px;
}

img.sidebar_toggler {
    transform: rotate(90deg);
}

select#station_selection,
select#station_selection_maps {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../assets/images/dropdown_icon.svg") !important;
    background-repeat: no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 60% !important;
    width: max-content;
    padding-right: 1em !important;
    font-weight: 500;
}

.react-datepicker__input-container {
    position: relative;
    width: max-content !important;
    margin: auto;
    display: block !important;
}

.daily_overview_top_chart_position .chart-wrapper {
    margin-bottom: 2em;
}

.react-datepicker-popper {
    margin-left: auto;
    margin-right: auto;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
}

.react-datepicker__tab-loop {
    width: 100%;
    margin: auto;
    display: block;
    position: relative;
}

.daily_overview_chart {
    position: relative;
    width: 100%;
}

.react-datepicker__input-container:before {
    content: url(../assets/images/calendar_icon.svg);
    position: absolute;
    left: 0.8em;
    top: 21%;
}

.custom-carousel .carousel-indicators .active {
    background-color: #32F5E6;
    opacity: 1 !important;
    width: 81px;
    height: 3px;
}

.custom-carousel .carousel-indicators [data-coreui-target] {
    background-color: #32F5E6;
    opacity: 0.37;
    width: 81px;
    height: 3px;
}

.custom-carousel .carousel-control-prev {
    padding-left: 0;
    justify-content: flex-start;
    opacity: 1;
}

.custom-carousel .carousel-control-next {
    padding-right: 0;
    justify-content: flex-end;
    opacity: 1;
}

.no_indicator_custom_carousel .carousel-indicators [data-coreui-target] {
    width: 42px;
}

.no_indicator_custom_carousel .carousel-indicators {
    position: relative;
    margin-right: 11%;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
}

.no_indicator_custom_carousel {
    display: flex;
    flex-direction: column-reverse;
}

.white-space-break-spaces {
    white-space: break-spaces;
}

.basic_link, .basic_link:hover {
    color: #ffffff;
    text-decoration: underline;
}

.basic_link {
    margin-left: 5px;
    margin-right: 5px;
}

.trajectories_weather_section {
    max-width: 240px;
}

.windy-wrapper iframe {
    width: 100%;
}

.blackFontColor {
    color: #000000 !important;
}

@media (min-width: 1921px) {
    .header-nav.d-none.d-md-flex.me-auto {
        width: calc(100% - 209px - 29.13px);
        justify-content: space-between;
    }

    li.nav-item.tab_nav_item {
        width: 100%;
    }
}

.toggled .sidebar_toggler {
    transform: rotate(-90deg);
}

#polarChart canvas {
    background-image: url("../assets/images/polar_bg.svg");
    background-repeat: no-repeat;
    background-size: 86%;
    background-position: 100% 74%;
    width: 206px !important;
    height: 290px !important;
}

.not_underlined_tab .nav-link {
    border-bottom: none !important;
}

.header .not_underlined_tab {
    display: none;
}

.header.header_toggled .not_underlined_tab {
    display: flex;
}

@media (max-width: 1400px) {
    .tab_nav_item a.nav-link {
        min-width: 260px;
    }

    .station_desc {
        max-width: 100%;
    }

    #map_info_wrapper, #station_info_wrapper, #map_station_wrapper {
        padding-left: 23px;
        padding-right: 23px;
    }

    .station_img {
        width: 100%;
        height: 172px;
    }

    .class_maps .flex-title, body[class*='class_station\/'] .flex-title {
        flex-direction: column;
    }

    #station_selection, #station_selection:focus, #station_selection:active, #station_selection_maps, #station_selection_maps:focus, #station_selection_maps:active {
        font-size: 25px;
    }

    .class_maps .station_info_gray, body[class*='class_station\/'] .station_info_gray {
        width: 100%;
    }

    .fw-medium.stat_name {
        font-size: 20px;
    }

    .table td {
        font-size: 18px;
    }

    .table th {
        font-size: 11px;
        padding: 0;
    }

    .real_view_img {
        width: 100%;
        height: 203px;
    }

    .station_tabs li.nav-item {
        max-width: 33%;
    }

    .map_info_desc {
        max-width: 100%;
        width: 80% !important;
    }

    #right_sidebar {
        padding-right: 0;
    }

    #right_sidebar .station_dates_info .d-flex.align-items-center,
    #right_sidebar .station_metrics .d-flex.align-items-end {
        flex-direction: column;
        align-items: flex-start !important;
    }

    #right_sidebar .station_dates_info .white_label,
    #right_sidebar .station_metrics .value,
    #right_sidebar .trajectories_weather_section .gray-station-info {
        margin-left: 0;
    }

    .class_maps .station_dates_info.d-flex {
        flex-direction: column;
    }

    .class_info #map_info_wrapper .map_info_desc {
        max-width: 100%;
        width: 100% !important;
    }
}